<template>
  <b-modal
    id="form-line-liff"
    :visible="shallShowLineLiffFormModal"
    :title="`${$t('Form')} ${$t('LineLiff')}`"
    footer-class="d-flex justify-content-between"
    size="lg"
    centered
    no-close-on-backdrop
    @change="(val) => $emit('update:shall-show-line-liff-form-modal', val)"
  >
    <!-- Modal Header -->
    <template #modal-header>
      <h5 class="modal-title">
        {{ `${$t(typeLineLiffForm)} ${$t('LineLiff')}` }}
      </h5>
      <div class="modal-actions">
        <feather-icon
          icon="MinusIcon"
          class="cursor-pointer"
          @click="(busy === false) ? $emit('update:shall-show-line-liff-form-modal', false) : false"
        />
        <!-- <feather-icon
          icon="Maximize2Icon"
          class="ml-1 cursor-pointer"
        /> -->
        <feather-icon
          icon="XIcon"
          class="ml-1 cursor-pointer"
          @click="(busy === false) ? onDiscard() : false"
        />
      </div>
    </template>

    <!-- Modal Footer -->
    <template #modal-footer>
      <!-- Footer: Left Content -->
      <div>
        <b-button
          v-if="(typeLineLiffForm !== 'View')"
          ref="submit"
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          variant="primary"
          right
          :disabled="busy"
          @click="onSubmit"
        >
          {{ $t('Save Data') }}
        </b-button>
      </div>

      <!-- Footer: Right Content -->
      <div>
        <!-- Discard -->
        <feather-icon
          icon="XCircleIcon"
          size="17"
          class="ml-75 cursor-pointer"
          @click="(busy === false) ? onDiscard() : false"
        />
      </div>
    </template>

    <!-- Modal: Body -->
    <validation-observer
      #default="{ handleSubmit }"
      ref="refFormObserver"
    >
      <div>
        <b-form
          ref="refForm"
          class="position-relative"
          @submit.prevent="handleSubmit(onSubmit)"
          @reset.prevent="resetForm"
        >
          <div>
            <b-card style="margin-bottom: 0.6rem;">
              <div>
                <b-row>
                  <b-col cols="12">

                    <b-row>
                      <!-- Field: Liff Size -->
                      <b-col
                        cols="6"
                      >
                        <b-form-group
                          v-if="(typeLineLiffForm === 'Add' || typeLineLiffForm === 'Edit')"
                          :label="$t('Liff Size')"
                          label-for="line-liff-size"
                        >
                          <validation-provider
                            #default="validationContext"
                            :name="$t('Liff Size')"
                            rules="required"
                          >
                            <v-select
                              v-model="lineLiffData.liff_size"
                              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                              :options="lineLiffData.liff_size_options"
                              :reduce="val => val.value"
                              :clearable="false"
                              input-id="line-liff-size"
                              :state="getValidationState(validationContext)"
                            />
                            <small class="text-danger">{{ validationContext.errors[0] }}</small>
                          </validation-provider>

                        </b-form-group>

                        <b-form-group
                          v-else
                          :label="$t('Liff Size')"
                          label-for="line-liff-size"
                        >
                          <span class="form-info-box">
                            <b-badge
                              pill
                              :variant="(skin === 'dark')? `light-primary` : `primary` "
                              style="margin-right: 4px;"
                            >
                              {{ textFirstUpper(lineLiffData.liff_size) }}
                            </b-badge>
                          </span>
                        </b-form-group>
                      </b-col>

                      <!-- Field: Login Channel ID -->
                      <b-col
                        cols="6"
                      >
                        <b-form-group
                          v-if="(typeLineLiffForm === 'Add' || typeLineLiffForm === 'Edit')"
                          :label="$t('Login Channel ID')"
                          label-for="line-liff-login-channel-id"
                        >
                          <validation-provider
                            #default="validationContext"
                            :name="$t('Login Channel ID')"
                            rules="required"
                          >
                            <b-form-input
                              id="line-liff-login-channel-id"
                              v-model="lineLiffData.login_channel_id"
                              :state="getValidationState(validationContext)"
                              :disabled="busy"
                            />
                            <small class="text-danger">{{ validationContext.errors[0] }}</small>
                          </validation-provider>

                        </b-form-group>

                        <b-form-group
                          v-else
                          :label="$t('Login Channel ID')"
                          label-for="line-liff-login-channel-id"
                        >
                          <span class="form-info-box">{{ lineLiffData.login_channel_id }}</span>
                        </b-form-group>
                      </b-col>
                    </b-row>
                    <b-row>
                      <!-- Field: Liff App Name -->
                      <b-col
                        cols="6"
                      >
                        <b-form-group
                          v-if="(typeLineLiffForm === 'Add' || typeLineLiffForm === 'Edit')"
                          :label="$t('Liff App Name')"
                          label-for="line-liff-name"
                        >
                          <validation-provider
                            #default="validationContext"
                            :name="$t('Liff App Name')"
                            rules="required"
                          >
                            <b-form-input
                              id="line-liff-name"
                              v-model="lineLiffData.liff_app_name"
                              :state="getValidationState(validationContext)"
                              :disabled="busy"
                            />
                            <small class="text-danger">{{ validationContext.errors[0] }}</small>
                          </validation-provider>

                        </b-form-group>

                        <b-form-group
                          v-else
                          :label="$t('Liff App Name')"
                          label-for="line-liff-name"
                        >
                          <span class="form-info-box">{{ lineLiffData.liff_app_name }}</span>
                        </b-form-group>
                      </b-col>

                      <!-- Field: Liff ID -->
                      <b-col
                        cols="6"
                      >
                        <b-form-group
                          v-if="(typeLineLiffForm === 'Add' || typeLineLiffForm === 'Edit')"
                          :label="$t('Liff ID')"
                          label-for="line-liff-id"
                        >
                          <validation-provider
                            #default="validationContext"
                            :name="$t('Liff ID')"
                            rules="required"
                          >
                            <b-form-input
                              id="line-liff-id"
                              v-model="lineLiffData.liff_id"
                              :state="getValidationState(validationContext)"
                              :disabled="busy"
                            />
                            <small class="text-danger">{{ validationContext.errors[0] }}</small>
                          </validation-provider>

                        </b-form-group>

                        <b-form-group
                          v-else
                          :label="$t('Liff ID')"
                          label-for="line-liff-id"
                        >
                          <span class="form-info-box">{{ lineLiffData.liff_id }}</span>
                        </b-form-group>
                      </b-col>

                    </b-row>

                    <b-row>
                      <!-- Field: Liff URL -->
                      <b-col
                        cols="6"
                      >
                        <b-form-group
                          v-if="(typeLineLiffForm === 'Add' || typeLineLiffForm === 'Edit')"
                          :label="$t('Liff URL')"
                          label-for="line-liff-url"
                        >
                          <validation-provider
                            #default="validationContext"
                            :name="$t('Liff URL')"
                            rules="required"
                          >
                            <b-form-input
                              id="line-liff-url"
                              v-model="lineLiffData.liff_url"
                              :state="getValidationState(validationContext)"
                              :disabled="busy"
                            />
                            <small class="text-danger">{{ validationContext.errors[0] }}</small>
                          </validation-provider>

                        </b-form-group>

                        <b-form-group
                          v-else
                          :label="$t('Liff URL')"
                          label-for="line-liff-url"
                        >
                          <span class="form-info-box">{{ lineLiffData.liff_url }}</span>
                        </b-form-group>
                      </b-col>

                      <!-- Field: Status -->
                      <b-col
                        cols="6"
                      >
                        <b-form-group
                          v-if="(typeLineLiffForm === 'Add' || typeLineLiffForm === 'Edit')"
                          :label="$t('Status')"
                          label-for="lineLiff-status"
                        >
                          <validation-provider
                            #default="validationContext"
                            :name="$t('Status')"
                            rules="required"
                          >
                            <b-form-radio
                              v-model="lineLiffData.status"
                              name="lineLiff-status"
                              value="active"
                              class="custom-control-success cursor-pointer"
                              inline
                            >
                              <span class="cursor-pointer">{{ $t('Active') }}</span>
                            </b-form-radio>
                            <b-form-radio
                              v-model="lineLiffData.status"
                              name="lineLiff-status"
                              value="inactive"
                              class="custom-control-info cursor-pointer"
                              inline
                            >
                              <span class="cursor-pointer">{{ $t('Inactive') }}</span>
                            </b-form-radio>
                            <small class="text-danger">{{ validationContext.errors[0] }}</small>
                          </validation-provider>

                        </b-form-group>

                        <b-form-group
                          v-else
                          :label="$t('Status')"
                          label-for="lineLiff-status"
                        >
                          <span class="form-info-box">
                            <b-badge
                              pill
                              :variant="`light-${isStatusVariant(lineLiffData.status)}`"
                              class="text-capitalize"
                            >
                              {{ $t(textFirstUpper(lineLiffData.status)) }}
                            </b-badge>
                          </span>
                        </b-form-group>
                      </b-col>
                    </b-row>

                  </b-col>
                </b-row>

              </div>
            </b-card>
          </div>

        </b-form>

      </div>

    </validation-observer>

    <b-overlay
      :show="busy"
      no-wrap
      @shown="onShown"
      @hidden="onHidden"
    >
      <template #overlay>
        <div
          v-if="processing"
          class="text-center p-2 bg-primary text-light rounded"
        >
          <feather-icon
            icon="UploadCloudIcon"
            size="20"
          />
          <div class="mb-2">
            {{ `${$t('Processing')}...` }}
          </div>
          <b-progress
            min="1"
            max="10"
            :value="counter"
            variant="success"
            height="6px"
            class="mx-n1"
          />
        </div>
        <div
          v-else
          ref="dialog"
          tabindex="-1"
          role="dialog"
          aria-modal="false"
          aria-labelledby="form-confirm-label"
          class="text-center p-3"
        >
          <b-card-text
            class="font-weight-bolder"
          >
            {{ $t('Are you sure?') }}
          </b-card-text>
          <div class="d-flex">
            <b-button
              v-ripple.400="'rgba(234, 84, 85, 0.15)'"
              variant="outline-danger"
              class="mr-3"
              @click="onCancel"
            >
              {{ $t('Cancel') }}
            </b-button>
            <b-button
              v-ripple.400="'rgba(40, 199, 111, 0.15)'"
              variant="outline-success"
              @click="onOK"
            >
              {{ $t('Ok') }}
            </b-button>
          </div>
        </div>
      </template>
    </b-overlay>
  </b-modal>
</template>

<script>
import {
  BButton, BForm,
  BFormGroup, BFormInput,
  BRow,
  BCol,
  BCard,
  BOverlay,
  BProgress,
  BCardText,
  BFormRadio,
  BBadge,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import {
  ValidationProvider,
  ValidationObserver,
} from 'vee-validate'
import formValidation from '@core/comp-functions/forms/form-validation'
import { required } from '@validations'
import store from '@/store'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import FeatherIcon from '@/@core/components/feather-icon/FeatherIcon.vue'
import vSelect from 'vue-select'

export default {
  directives: {
    Ripple,
  },
  components: {

    BButton,
    BForm,
    BFormGroup,
    BFormInput,
    BOverlay,
    BRow,
    BCol,
    BCard,
    BProgress,
    BCardText,
    BFormRadio,
    BBadge,

    vSelect,
    ValidationProvider,
    ValidationObserver,
    FeatherIcon,
  },
  model: {
    prop: 'shallShowLineLiffFormModal',
    event: 'update:shall-show-line-liff-form-modal',
  },
  props: {
    shallShowLineLiffFormModal: {
      type: Boolean,
      required: true,
    },
    typeLineLiffForm: {
      type: String,
      default: 'Add',
    },
    lineLiffData: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      required,
      busy: false,
      processing: false,
      counter: 1,
      interval: null,
      skin: store.state.appConfig.layout.skin,
    }
  },
  computed: {
    lineOa() {
      const value = store.state.lineapi.lineOa
      if (value === '' || value === undefined) {
        return ''
      }
      return JSON.parse(value)
    },
    lineOaId() {
      const value = this.lineOa.id
      if (value === '' || value === undefined) {
        return ''
      }
      return value
    },
  },
  methods: {
    textFirstUpper(text) {
      return text.slice(0, 1).toUpperCase() + text.slice(1, text.length)
    },
    isStatusVariant(isstatus) {
      if (isstatus === 'active') return 'success'
      if (isstatus === 'inactive') return 'danger'
      return 'danger'
    },
    onShown() {
      // Focus the dialog prompt
      this.$refs.dialog.focus()
    },
    onHidden() {
      // In this case, we return focus to the submit button
      // You may need to alter this based on your application requirements
      this.$refs.submit.focus()
    },
    onSubmit() {
      this.$refs.refFormObserver.validate().then(success => {
        if (success) {
          this.processing = false
          this.busy = true
        }
      })
    },
    onCancel() {
      this.busy = false
    },
    onOK() {
      this.processing = true
      if (this.typeLineLiffForm === 'Add') {
        this.lineLiffData.line_oa_id = this.lineOaId
        store.dispatch('line-liff-store/addLineLiff', this.lineLiffData)
          .then(() => {
            const okMsg = this.$i18n.t('Process on successfully')
            this.$toast({
              component: ToastificationContent,
              props: {
                title: okMsg,
                icon: 'CheckCircleIcon',
                variant: 'success',
              },
            },
            {
              position: 'bottom-right',
            })
            this.busy = false
            this.processing = false

            this.$emit('update-line-liff-form')
            this.$emit('update:shall-show-line-liff-form-modal', false)
          })
          .catch(error => {
            const { response, message } = error
            if (response) {
              const errorMsg = this.$i18n.t(response.data.error)
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: errorMsg,
                  icon: 'AlertTriangleIcon',
                  variant: 'danger',
                },
              },
              {
                position: 'bottom-right',
              })
            } else if (message) {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: error.message,
                  icon: 'AlertTriangleIcon',
                  variant: 'danger',
                },
              },
              {
                position: 'bottom-right',
              })
            }
            this.busy = false
            this.processing = false
          })
      } else if (this.typeLineLiffForm === 'Edit') {
        store.dispatch('line-liff-store/editLineLiff', { id: this.lineLiffData.id, data: this.lineLiffData })
          .then(() => {
            const okMsg = this.$i18n.t('Process on successfully')
            this.$toast({
              component: ToastificationContent,
              props: {
                title: okMsg,
                icon: 'CheckCircleIcon',
                variant: 'success',
              },
            },
            {
              position: 'bottom-right',
            })
            this.busy = false
            this.processing = false

            this.$emit('update-line-liff-form')
            this.$emit('update:shall-show-line-liff-form-modal', false)
          })
          .catch(error => {
            const { response, message } = error
            if (response) {
              const errorMsg = this.$i18n.t(response.data.error)
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: errorMsg,
                  icon: 'AlertTriangleIcon',
                  variant: 'danger',
                },
              },
              {
                position: 'bottom-right',
              })
            } else if (message) {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: error.message,
                  icon: 'AlertTriangleIcon',
                  variant: 'danger',
                },
              },
              {
                position: 'bottom-right',
              })
            }
            this.busy = false
            this.processing = false
          })
      } else if (this.typeLineLiffForm === 'Delete') {
        store.dispatch('line-liff-store/deleteLineLiff', {
          id: this.lineLiffData.id,
        })
          .then(() => {
            const okMsg = this.$i18n.t('Process on successfully')
            this.$toast({
              component: ToastificationContent,
              props: {
                title: okMsg,
                icon: 'CheckCircleIcon',
                variant: 'success',
              },
            },
            {
              position: 'bottom-right',
            })
            this.busy = false
            this.processing = false

            this.$emit('update-line-liff-form')
            this.$emit('update:shall-show-line-liff-form-modal', false)
          })
          .catch(error => {
            const { response, message } = error
            if (response) {
              const errorMsg = this.$i18n.t(response.data.error)
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: errorMsg,
                  icon: 'AlertTriangleIcon',
                  variant: 'danger',
                },
              },
              {
                position: 'bottom-right',
              })
            } else if (message) {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: error.message,
                  icon: 'AlertTriangleIcon',
                  variant: 'danger',
                },
              },
              {
                position: 'bottom-right',
              })
            }
            this.busy = false
            this.processing = false
          })
      }
    },
    onDiscard() {
      this.$emit('discard-line-liff-form')
      this.$emit('update:shall-show-line-liff-form-modal', false)
    },
  },
  setup() {
    const {
      refFormObserver,
      getValidationState,
      resetForm,
      clearForm,
    } = formValidation()

    return {
      resetForm,
      clearForm,
      refFormObserver,
      getValidationState,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>

<style lang="scss">
@import '~@core/scss/base/bootstrap-extended/_variables';
@import '~@core/scss/base/bootstrap-extended/include';
@import '~@core/scss/base/components/variables-dark';

.modal{
  .line-height-info{
    padding-bottom: 0.6rem;
    &.end{
      padding-bottom: 0;
    }
  }
  .modal-header,
  .modal-header[class*='bg-'] {
    background-color: #fff !important;
  }
  .modal-body {
    background-color: $body-bg !important;
  }
}
.form-info-box{
  display: flex;
  padding: 0.6rem 1rem;
  background-color: #f8f8f8;
  background-clip: padding-box;
  border: 1px solid #f0f0f0;
  border-radius: 0.357rem;
}

.dark-layout {
  .modal{
    .line-height-info{
      padding-bottom: 0.6rem;
      &.end{
        padding-bottom: 0;
      }
    }
    .modal-header,
    .modal-header[class*='bg-'] {
      background-color: $theme-dark-body-bg !important;
    }
    .modal-body {
      background-color: $theme-dark-table-active-bg !important;
    }
  }
  .form-info-box{
    display: flex;
    padding: 0.6rem 1rem;
    background-color: $theme-dark-input-bg;
    background-clip: padding-box;
    border: 1px solid $theme-dark-border-color;
    border-radius: 0.357rem;
  }
}
.jsoneditor-poweredBy {
  display: none !important;
}
.jsoneditor {
  border: thin solid #d8d6de !important;
}
.jsoneditor-menu{
  background-color: #aaaaaa !important;
  border-bottom: 1px solid #aaaaaa !important;
}
</style>
