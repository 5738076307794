import axios from '@axios'

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    getListLineLiff(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .post('/lineapi/get_list_line_liff', params)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    getLineLiff(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .post('/lineapi/get_line_liff', params)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    addLineLiff(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .post('/lineapi/add_line_liff', params)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    editLineLiff(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .post('/lineapi/edit_line_liff', params)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    deleteLineLiff(ctx, id) {
      return new Promise((resolve, reject) => {
        axios
          .post(`/lineapi/delete_line_liff/${id}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    getDefaultLineLiff(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .get('/lineapi/get_default_line_liff', { params })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    getListLineLiffSelect(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .post('/lineapi/get_list_line_liff_select', params)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
  },
}
